// 创建文件zh.js
const zh = {
  test: '你好',
  language: {
    zh: '中文',
    en: 'English',
  },

  common: {
    cancel: 'cancel',
    confirm: 'Confirm',
    submit: 'Commit',
  },

  tab: {
    home: 'Home',
    map: 'Map',
    department: 'Departments',
    hospital: 'Intro.',
    nav: 'Navigation',
    findCar: 'Find Car',
    mine: 'Mine',
    search: 'Search'
  },

  hospital: {
    title: 'Introduction',
    contentTitle: 'Hotpital Info.'
  },

  moreFunctions: {
    mine: 'mine',
    come: 'come',
    come2: 'come',
    exit: 'exit',
    around: 'around',
    introduction: 'intro.',
    feedback: 'feedback',
    nearby: 'nearby',
    language: '简体',
    help: 'help',
    collection: 'collection',
    speech: 'speech',
    refresh: "refresh",
    careful: "careful",
    change: "change"
  },

  home: {
    internalRoute: 'Internal route',
    analogNav: 'Navigation',
    nav: 'start nav.',
    share: 'Share Address',
    collect: 'Collect',
    collected: 'Collected',
    addrError: 'Adrress Error',
    to: 'To Here',
    go: 'Go',
    clearHistory: 'Clear history',
    clearHistoryContent: 'Are you sure to clear all history records?',
    noHistory: 'No history here.',
    out: 'Exit',
    outCamera: 'Exit Live',
    viewRoute: 'View Route',
    viewOrigin: 'Origin',
    viewDestination: 'Destination',
    positionError: 'Position error',
    errorNote: 'Please enter information like departments name error.',
    wechatNote: 'Please use it in wechat app.',
    viewHospitalMap: 'View Hospital Map',
    lift: 'Lift',
    stair: 'Stair',
    destination: 'Destiantion',
    holdToSay: 'Hold to say',
  }
}
export default zh
