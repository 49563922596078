import {PUSH_WEBSOCKET} from './api'
import {getStore} from './storage'
var websock = null
var rec //断线重连后，延迟5秒重新创建WebSocket连接  rec用来存储延迟请求的代码
var isConnect = false //连接标识 避免重复连接
var checkMsg = "heartbeat" //心跳发送/返回的信息 服务器和客户端收到的信息内容如果如下 就识别为心跳信息 不要做业务处理
var reTryCount = 0
var globalCallback = function() {}

const createWebSocket = () => {
  try {
    const ws = PUSH_WEBSOCKET + getStore({name: 'user_key'})
    console.log('获取beacon websocket url' + ws)
    initWebSocket(ws) // 初始化websocket连接
  } catch (e) {
    console.log("尝试创建连接失败", e)
    reConnect() //如果无法连接上webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
  }
}

//定义重连函数
const reConnect = () => {
  console.log("尝试重新连接")
  if (isConnect) return //如果已经连上就不在重连了
  rec && clearTimeout(rec)
  rec = setTimeout(function() { // 延迟5秒重连  避免过多次过频繁请求重连
    reTryCount++
    if (reTryCount > 5) {
      return
    }
    createWebSocket()
  }, 5000)
}
//设置关闭连接
const closeWebSocket = () => {
  if (isConnect) {
    websock.close()
  }
}

//心跳设置
const heartCheck = {
  timeout: 20000, //每段时间发送一次心跳包 这里设置为20s
  timeoutObj: null, //延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）

  start: function() {
    this.timeoutObj = setTimeout(function() {
      if (isConnect) websock.send(checkMsg)
    }, this.timeout)
  },

  reset: function() {
    clearTimeout(this.timeoutObj)
    this.start()
  }
}

// 初始化websocket
function initWebSocket(ws) {
  if (isConnect) return
  websock = new WebSocket(ws)
  websock.onmessage = function(e) {
    websocketonmessage(e)
  }
  websock.onclose = function(e) {
    websocketclose(e)
  }
  websock.onopen = function() {
    websocketOpen()
    reTryCount = 0
    console.log('onopen')
  }

  // 连接发生错误的回调方法
  websock.onerror = function(e) {
    console.log('WebSocket连接发生错误', e)
    isConnect = false //连接断开修改标识
    reConnect() //连接错误 需要重连
  }
}

// 实际调用的方法
function sendSock(agentData, callback) {
  globalCallback = callback
  // console.log(globalCallback)
  if (websock.readyState === websock.OPEN) {
    // 若是ws开启状态
    websocketsend(agentData)
  } else if (websock.readyState === websock.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function() {
      sendSock(agentData, callback)
    }, 1000)
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function() {
      sendSock(agentData, callback)
    }, 1000)
  }
}

function getSock(callback) {
  globalCallback = callback
}
// 数据接收
function websocketonmessage(e) {
  const data = JSON.parse(e.data)
  if (!data) {
    heartCheck.reset()
  } else {
    globalCallback(data)
  }
}

// 数据发送
function websocketsend(agentData) {
  console.log(JSON.stringify(agentData))
  websock.send(JSON.stringify(agentData))
}

// 关闭
function websocketclose(e) {
  isConnect = false //断开后修改标识
}

// 创建 websocket 连接
function websocketOpen(e) {
  isConnect = true
}

// 将方法暴露出去
export default {
  sendSock,
  getSock,
  createWebSocket,
  closeWebSocket
}
