import Vue from 'vue'
import Vuex from 'vuex'
import {setStore, getStore} from '@/util/storage.js'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'zh', // 语言
    currentPosition: {longitude: 0, latitude: 0}, // 当前用户位置，信标配合gps
    sharedUserPosition: {longitude: 0, latitude: 0}, // 分享用户的位置
    gpsPosition: {longitude: 0, latitude: 0}, // 当前用户位置，GPS定位结果
    currentRefBeacon: {}, // 最新的信标参考信息
    sharedUserRefBeacon: {}, // 分享用户最新的信标参考信息
    userKey: '1234567', // 用户key，小程序端自动生成的
    sharedUserKey: '', // 分享用户的key
    entryUrl: '',
    onWeChatPlatform: false, // 是否在微信浏览器的环境下
    realNavVisible: false, // 是否检测到周围有信标，如果检测到，就开启真实导航
    useRealNav: false, // 正在使用实时导航
    liftActive: 1, // 默认电梯模式
    tabActive: 0, // 默认tab在主页
    tabVisible: true, // 是否现在底部导航栏
    topBackVisible: true,
    topLeftVisible: true,
    topRightVisible: true,
    bottomLeftVisible: true,
    bottomRightVisible: true,
    departmentPopupVisible: false, // 楼栋界面是否显示
    deparmentPoiVisible: true, // 楼栋菜单是否显示
    hospitalPopupVisible: false, // 医院信息界面是否显示
    minePopupVisible: false, // 我的页面是否需要显示
    myCenterPopupVisible: false, // 个人中心
    sharePopupVisible: false, // 分享界面是否显示
    speechRecognitionPopupVisible: false, // 是否显示语音识别界面
    speechRecognitionVisible: true, // 是否显示语音识别按钮
    bottomSearchWidgetVisible: true, // 底部搜索框是否显示
    aroundPopupVisible: false, // 周边是否显示
    helpPopupVisible: false, // 周边是否显示
    videoInfoVisible: false, // 视频介绍页面是否显示
    cameraVisible: false, // 实景导航是否开启
    liveNavVisible: false, // 实景导航按钮是否显示
    originFeatureLoadingVisible: false, // 起点搜索loading
    backMode: 0, // 科室页面点击返回时标记处理各种情况 0-返回菜单 1-返回主页 2-返回出发页面
    routePlanningMode: 0, // 是否路径规划模式 0-无 1-终点选择模式 2-起点选择模式 3-导航模式
    moreFunsVisible: false, // 主页是否显示更多功能界面
    backBtnOnHomeVisible: true, // 主页在routePlanningMode=0的时候是否显示返回按钮
    destinationFeature: {}, // 终点位置信息，包含名称，楼层，经纬度
    originFeature: {}, // 起点位置信息，包含名称，楼层，经纬度
    destinationFeatureList: [], // 待选择列表地点
    pointChooseMode: 1, // 1 选择终点，0选择起点
    currentFloor: {index: 0, name: '1F'}, // 当前楼层
    locateButtonVisible: true, // 是否显示定位按钮
    poiHistory: [], // 搜索历史记录
    poiOnSearch: [], // 搜索结果记录
    positionList: [], // 收藏记录
    analogNavTopGuide: '开始出发', // 模拟导航引导语句
    analogNavTopSpeed: '正常', // 模拟导航速度
    analogNavRemainDistance: 0, // 模拟导航剩余距离 米
    analogNavDistance: 1, // 模拟导航总距离
    directionIcon: 3, // 模拟导航时的方向图标，向前
    routes: [], // 所有路径,可能有多个，因为是多楼层
    positionError: true, // 是否显示地址错误反馈按钮
    noteMarkers: [], // 用于提示的marker，一般放在电梯，或大楼门口
    poiChooseListNote: '', // poi选择标题
    cameraDerectionImage: 1,
    cameraDerectionImageVisible: false,
    comePopupVisible: false, // 来院（办）导航时候显示，H5情况下使用
    comePopupUrl: '', // 来院（办）导航url
    startConfig: false, // 是否开启配置功能
    showConfigView: false, // 是否显示配置页面
    isDebug: false, // 是否开启调试功能
    isRequestDeviceOrientation: false,
    multiPath: false, // 是否显示多条路径
    nearbyRes: {}, // 计算所有可走电梯的结果
    latestNearbyRes: {}, // 如果导航时，用户偏离轨迹，需要重新规划路线，依然采用上次用户选择的楼梯或电梯作为目标
    routeMarkers: [], // 缓存计算出来的路线
    showLiftImage: false, // 是否显示楼层切换时的实景图片
    liftImageUrl: '', // 图标地址
    liftImageText: '', // 提示文字
    widgetsWrapperVisible: true, // 是否显示左右控件
    showWexinQRcode: false, // 是否弹出小程序二维码框
    largeScreen: false, // 是否是大屏系统
    largeScreenShowSearch: true, // 大屏模式下是否显示底部的热门科室等信息
    convertWgs84: false, // 是否转换为wgs84坐标
    mp3Url: '', // 音频地址
    lastOperateTime: 0, // 上一次界面有操作的时间戳,
    is2D: false, // 是否开启2D模式
    isCareful: false, // 是否开启关怀模式
    isStopSimulateNav : false, // 是否暂停模拟导航
  },
  getters: {
    getCurrentPosition: state => state.currentPosition,
    getStopSimulateNav: state => state.isStopSimulateNav,
    get2D: state => state.is2D,
    getCareful: state => state.isCareful,
    getUserKey: state => state.userKey,
    getTabVisible: state => state.tabVisible,
    getTopBackVisible: state => state.topBackVisible,
    getTopLeftVisible: state => state.topLeftVisible,
    getTopRightVisible: state => state.topRightVisible,
    getBottomLeftVisible: state => state.bottomLeftVisible,
    getBottomRightVisible: state => state.bottomRightVisible,
    getDepartmentPopupVisible: state => state.departmentPopupVisible, // 楼栋界面是否显示
    getDeparmentPoiVisible: state => state.deparmentPoiVisible, // 楼栋菜单是否显示
    getHospitalPopupVisible: state => state.hospitalPopupVisible, // 医院信息界面是否显示
    getSpeechRecognitionVisible: state => state.speechRecognitionVisible, // 医院信息界面是否显示
    getBottomSearchWidgetVisible: state => state.bottomSearchWidgetVisible,
    getLocateButtonVisible: state => state.locateButtonVisible,
    getPoiHistory: state => state.poiHistory,
    getPoiOnSearch: state => state.poiOnSearch,
    analogNavTopGuide: state => state.analogNavTopGuide,
    directionIcon: state => state.directionIcon,
    analogNavRemainDistance: state => state.analogNavRemainDistance,
    analogNavDistance: state => state.analogNavDistance,
    positionError: state => state.positionError,
    currentFloor: state => state.currentFloor,
    destinationFeatureList: state => state.destinationFeatureList,
    pointChooseMode: state => state.pointChooseMode,
    getRoutesLen: state => state.routes.length,
    comePopupUrl: state => state.comePopupUrl,
    comePopupVisible: state => state.comePopupVisible,
    onWeChatPlatform: state => state.onWeChatPlatform,
    liftActive: state => state.liftActive,
    noteMarkers: state => state.noteMarkers,
    backBtnOnHomeVisible: state => state.backBtnOnHomeVisible,
    multiPath: state => state.multiPath,
    showLiftImage: state => state.showLiftImage,
    liftImageUrl: state => state.liftImageUrl,
    liftImageText: state => state.liftImageText,
    getWidgetsWrapperVisible: state => state.widgetsWrapperVisible,
    getShowWexinQRcode: state => state.showWexinQRcode,
    getLargeScreen: state => state.largeScreen,
    getPositionList: state => state.positionList,
    getStartConfig: state => state.startConfig,
    getMp3Url: state => state.mp3Url,
    getLargeScreenShowSearch: state => state.largeScreenShowSearch,
    getShowConfigView: state => state.showConfigView
  },
  mutations: {
    UPDATE_STATE(state, payload) {
      state[payload.key] = payload.val
    },
    SET_USER_KEY(state, val) {
      state.userKey = val
    },
  },
  actions: {
    setUserKey(context, value) {
      if (value && value.length > 0) {
        setStore({name: 'user_key', content: value})
        context.commit('SET_USER_KEY', value)
      }
    },

    switchLanguage(context) {
      let lang = 'zh'
      if (this.state.language == 'zh') {
        lang = 'en'
      }
      i18n.locale = lang
      context.commit('UPDATE_STATE', {key: 'language', val: lang})
      setStore({name: 'language', content: lang})
    },

    setRoutePlanningMode(context, value) {
      context.commit('UPDATE_STATE', {key: 'routePlanningMode', val: value})
    },

    setDestinationFeature(context, value) {
      context.commit('UPDATE_STATE', {key: 'destinationFeature', val: value})
    },

    setOriginFeature(context, value) {
      context.commit('UPDATE_STATE', {key: 'originFeature', val: value})
    },

    setPoiHistory(context, value) {
      if (value) {
        context.commit('UPDATE_STATE', {key: 'poiHistory', val: value})
        let hospitalJsonId = getStore({name: 'hospitalJsonId'})
        setStore({name: 'poi_history', storageKey: hospitalJsonId, content: value})
      }
    },

    setAnalogNavRemainDistance(context, value) {
      context.commit('UPDATE_STATE', {key: 'analogNavRemainDistance', val: value})
    },

    setAnalogNavDistance(context, value) {
      context.commit('UPDATE_STATE', {key: 'analogNavDistance', val: value})
    },

    setRoutes(context, value) {
      context.commit('UPDATE_STATE', {key: 'routes', val: value})
    }
  },
})
