// 创建文件en.js
const en = {
  test: 'hello',
  language: {
    zh: '中文',
    en: 'English',
  },

  common: {
    cancel: '取消',
    confirm: '确定',
    submit: '提交',
  },

  tab: {
    home: '首页',
    map: '地图',
    department: '科室',
    hospital: '机构信息',
    nav: '导航',
    findCar: '寻车',
    mine: '我的',
    search: '搜索'
  },

  hospital: {
    title: '介绍'
  },

  moreFunctions: {
    mine: '我的',
    come: '来院',
    come2: '来办',
    exit: '逃生',
    introduction: '介绍',
    around: '周边',
    feedback: '反馈',
    nearby: '附近',
    language: 'ENG',
    help: '帮助',
    collection: '收藏',
    speech: '语音',
    refresh: "刷新",
    careful: "关怀",
    change: "切换"
  },

  home: {
    internalRoute: '内部路线',
    analogNav: '模拟导航',
    nav: '开启导航',
    share: '分享位置',
    collect: '收藏',
    collected: '已收藏',
    addrError: '地址错误',
    to: '到这去',
    go:'出发',
    clearHistory: '清空历史记录',
    clearHistoryContent: '确定情况所有历史记录吗?',
    noHistory: '没有历史记录',
    out: '退出',
    outCamera: '退出实景',
    viewRoute: '路径预览',
    viewOrigin: '起 点',
    viewDestination: '终 点',
    positionError: '位置错误',
    errorNote: '请输入错误信息, 如科室名称错误',
    wechatNote: '请在微信里使用该功能！',
    viewHospitalMap: '查看室内地图',
    lift: '电梯',
    stair: '楼梯',
    destination: '目的地',
    holdToSay: '按住说话',
  }
}
export default en
