import {validatenull} from '@/util/validate'

var keyName = 'dragonfly_'
/**
 * 存储localStorage
 */
export const setStore = (params = {}) => {
  let {name, storageKey, content, type} = params
  // localstorage中取出key
  if (storageKey) {
    name = storageKey + '_' + name
  } else {
    name = keyName + name
  }
  let obj = {
    dataType: typeof (content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  }

  const objStr = JSON.stringify(obj)
  if (objStr) {
    if (objStr.length > (4 * 1024 * 1024)) {
      console.error("警告！警告！医院geojson数据可能超过sessionStorage的存储限制，请核查！！！")
    }
  }
  if (type) window.sessionStorage.setItem(name, objStr)
  else window.localStorage.setItem(name, objStr)
}
/**
 * 获取localStorage
 */

export const getStore = (params = {}) => {
  let {name, storageKey, debug} = params
  // localstorage中取出key
  if (storageKey) {
    name = storageKey + '_' + name
  } else {
    name = keyName + name
  }
  console.log(name)
  let obj = {},
    content
  obj = window.sessionStorage.getItem(name)
  if (validatenull(obj)) obj = window.localStorage.getItem(name)
  if (validatenull(obj)) return
  try {
    obj = JSON.parse(obj)
  } catch {
    return obj
  }
  if (debug) {
    return obj
  }
  if (obj.dataType == 'string') {
    content = obj.content
  } else if (obj.dataType == 'number') {
    content = Number(obj.content)
  } else if (obj.dataType == 'boolean') {
    content = eval(obj.content)
  } else if (obj.dataType == 'object') {
    content = obj.content
  }
  return content
}
/**
 * 删除localStorage
 */
export const removeStore = (params = {}) => {
  let {name, storageKey, type} = params
  // localstorage中取出key
  if (storageKey) {
    name = storageKey + '_' + name
  } else {
    name = keyName + name
  }
  if (type) {
    window.sessionStorage.removeItem(name)
  } else {
    window.localStorage.removeItem(name)
  }

}

/**
 * 获取全部localStorage
 */
export const getAllStore = (params = {}) => {
  let list = []
  let {type} = params
  if (type) {
    for (let i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      })
    }
  } else {
    for (let i = 0; i <= window.localStorage.length; i++) {
      list.push({
        name: window.localStorage.key(i),
        content: getStore({
          name: window.localStorage.key(i),
        })
      })
    }
  }
  return list
}

/**
 * 清空全部localStorage
 */
export const clearStore = (params = {}) => {
  let {type} = params
  if (type) {
    window.sessionStorage.clear()
  } else {
    window.localStorage.clear()
  }
}