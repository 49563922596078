
export const UPLOAD_WEBSOCKET = 'wss://qt-nav.scqtkj.com/api/websocket/upload/'
export const PUSH_WEBSOCKET = 'wss://qt-nav.scqtkj.com/api/websocket/push/'

// 上传错误信息地址
export const UPLOAD_ERROR_ADDRESS = 'https://qt-nav.scqtkj.com/api/nav/uploadErrorAddress'
export const GET_ERROR_ADDRESS_BY_OPENID_AND_HOSID = 'https://qt-nav.scqtkj.com/api/nav/getErrorAddressByOpenidAndHosId?'
export const DELETE_ERROR_ADDRESS_BY_ID = 'https://qt-nav.scqtkj.com/api/nav/deleteErrorAddressById?id='
export const DEV_WITH_BEACONS = 'https://qt-nav.scqtkj.com/api/nav/withBeacons?userKey='
export const UPLOAD_COLLECTION = 'https://qt-nav.scqtkj.com/api/nav/uploadCollection'
export const DELETE_COLLECTION = 'https://qt-nav.scqtkj.com/api/nav/deleteCollection?'

// 上传信标信息
export const ADD_BEACON_STATION_URL = 'https://qt-nav.scqtkj.com/api/nav/addBeaconStation' //'https://indoor-nav.scqtkj.com/api/nav/addBeaconStation'
export const SELECT_BY_HOSPITALID_AND_FLOOR = 'https://qt-nav.scqtkj.com/api/nav/selectByHospitalIdAndFloor'

// admin
export const EDIT_MAP_VERIFY = 'https://qt-nav.scqtkj.com/api/nav/editMapVerify'
export const UPDATE_GEO = 'https://qt-nav.scqtkj.com/api/nav/updateGeo'
export const UPLOAD_GEO = 'https://qt-nav.scqtkj.com/api/nav/uploadGeo'
export const GET_GEO = 'https://qt-nav.scqtkj.com/api/nav/getGeo'
export const DOWNLOAD_GEO = 'https://qt-nav.scqtkj.com/api/nav/downloadGeo'

// 登录相关
export const FIND_USER_BY_USER_KEY = 'https://qt-nav.scqtkj.com/api/wx/findUserByUserKey?userKey='
export const GET_ADDR_ERROR_BY_OPENID = 'https://qt-nav.scqtkj.com/api/wx/getAddrErrorByOpenid?openid='
export const GET_COLLECTION_BY_OPENID = 'https://qt-nav.scqtkj.com/api/wx/getCollectionByOpenid?openid='
export const fIND_USER_BY_OPENID = 'https://qt-nav.scqtkj.com/api/wx/findUserByOpenid?'

export const GET_WX_APP_URL_LINK = 'https://qt-nav.scqtkj.com/api/wx/getWxAppUrlLink?hospitalId='

export const GET_USER_LIST = 'https://qt-nav.scqtkj.com/api/admin/getUserList'
export const DELETE_USER_BY_ID = 'https://qt-nav.scqtkj.com/api/admin/deleteUserById'

export const GET_ADDRESS_ERROR_LIST = 'https://qt-nav.scqtkj.com/api/admin/getAddressErrorList'
export const DELETE_ADDRESS_ERROR_BY_ID = 'https://qt-nav.scqtkj.com/api/admin/deleteAddressErrorById'

export const UPLOAD_DEPARTMENTS = 'https://qt-nav.scqtkj.com/api/admin/uploadDepartments'
export const UPLOAD_HOT_DEPARTMENT = 'https://qt-nav.scqtkj.com/api/admin/uploadHotDepartment'
export const UPLOAD_ROUTE_DATA = 'https://qt-nav.scqtkj.com/api/admin/uploadRouteData'