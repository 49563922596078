import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/entry'
  },

  {
    path: '/entry',
    name: 'Entry',
    component: () => import('@/views/entry/entry.vue'),
  },

  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/map.vue'),
  },

  {
    path: '/editMap',
    name: 'EditMap',
    component: () => import('@/views/admin/edit-map.vue'),
  },

  {
    path: '/portrait',
    name: 'Portrait',
    component: () => import('@/views/portrait/portrait.vue'),
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/admin.vue'),
  },

  {
    path: '/adminLogin',
    name: 'AdminLogin',
    component: () => import('@/views/admin/admin-login.vue'),
  }

]

const router = new VueRouter({
  mode: 'history', // 如果打包相对路径需要改成hash
  base: process.env.BASE_URL, // process.env.BASE_URL,
  routes
})

export default router
