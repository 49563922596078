<template>
  <div class="dialog">
    <van-overlay :show="show" @click="$emit('overlayClick')">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="dialog-label">
            <img src="../assets/images/qt-dialog-label.png"></div>
          <div class="dialog-content">将&nbsp;
            <span v-if="content.length > 10" style="color: #2783FF;width: 180px;">
              <marquee>{{ content }}</marquee>
            </span>
            <span v-else style="color: #2783FF;">{{ content }}</span>
            &nbsp;设为
          </div>
          <div class="dialog-button">
            <div class="cancel" @click="$emit('cancel')">
              <div class="cancel-button">{{ cancelButtonText ? cancelButtonText : $t('common.cancel') }}</div>
            </div>
            <div class="confirm" @click="$emit('confirm')">
              <div class="confirm-button">{{ confirmButtonText ? confirmButtonText : $t('common.confirm') }}</div>
            </div>
          </div>
          <img @click="$emit('close')" class="close-icon" src="../assets/images/icon-close.png" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import marquee from './public-marquee.vue'
export default {
  name: 'qt-dialog',
  comments: {
    marquee
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  border-radius: 10px;
  background-color: #fff;
  max-width: 380px;
}

.van-overlay {
  z-index: 3001;
}

.dialog-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    height: 160px;
  }
}

.dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #172b4d;
}

.dialog-button {
  display: flex;
  text-align: center;
  line-height: 40px;
}

.cancel {
  width: 50%;
  height: 66px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.confirm {
  width: 50%;
  height: 66px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.cancel-button {
  width: 115px;
  height: 40px;
  border: 1px solid #2783FF;
  background: #2783FF;
  color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-button {
  width: 115px;
  height: 40px;
  color: #ffffff;
  background: #e73225;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  height: 60px;
  margin: 0 0 -70px calc(50% - 30px);
}
</style>