import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import i18n from './i18n'
import webSocket from './util/websocket'
import mapboxgl from '!mapbox-gl'
import * as turf from '@turf/turf'
import 'mapbox-gl/dist/mapbox-gl.css'
import './assets/css/variables.css'
import './assets/css/global.css'
import 'animate.css'
import qDialog from '@/components/qt-dialog.vue'
import ElementUI from 'element-ui'
//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css'
import pagination from '@/components/pagination.vue'
import dialogDrag from '@/util/directive/drag'

import {Lazyload, Search, Toast, Icon, NavBar, Popup, Dialog, Tabbar, TabbarItem, Tabs, Tab, ActionSheet, Collapse, CollapseItem, Overlay, Picker, Swipe, SwipeItem, Loading, Field, CellGroup, RadioGroup, Radio, Image as VanImage, Divider} from 'vant'

import VConsole from 'vconsole'
const vConsole = new VConsole() // {theme: 'dark'}
Vue.prototype.$vConsole = vConsole


Vue.prototype.$turf = turf
Vue.prototype.$webSocket = webSocket
Vue.prototype.$axios = axios
Vue.prototype.$mapboxgl = mapboxgl // 挂载mapboxgl到Vue上
mapboxgl.accessToken = 'pk.eyJ1IjoiZ3VvbGl1eWFuZyIsImEiOiJjajJtMmFncmQwMHFhMzNydGF6ZGduN2h0In0.imqOkUMERwvj77jk5bcJaQ'
Vue.config.productionTip = false
Vue.use(Search)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(ActionSheet)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Loading)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Divider)
Vue.use(ElementUI)
Vue.component('qt-pagination', pagination)
Vue.component('qt-dialog', qDialog)

Vue.directive('dialogDrag', dialogDrag)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
