<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

  mounted() {
    if (!this.$store.state.isDebug) {
      let vConsole = document.getElementById('__vconsole')
      if (vConsole) {
        vConsole.style.display = 'none'
      }
    }
  }
}
</script>
<style lang="less">
@import "@/assets/css/mixin.less";

#app {
  -webkit-overflow-scrolling: touch;
  color: var(--uni-text-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  z-index: 0;
  .add-size();
}

body {
  display: block;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  text-rendering: optimizeLegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/**.my-mapboxgl-user-location 定位图标UI样式 */
.my-mapboxgl-user-location {
  transform: translate(195px, 397px) translate(-50%, -50%) translate(0px, 0px);
  opacity: 1;
  pointer-events: auto;
}

/**.my-mapboxgl-user-location 定位图标UI样式 */

/** .destination-marker origin-marker 定义起始和终止marker样式 dom.js那边创建的*/

/** .destination-marker origin-marker 定义起始和终止marker样式 dom.js那边创建的*/


/** popup 图标样式 */
.mapboxgl-popup-content {
  background: #5c5c5ccc !important;
  padding: 5px 6px !important;
  color: white;
}

.mapboxgl-popup-tip {
  border-top-color: rgba(92, 92, 92, .8) !important;
}

.mapboxgl-popup-text {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
}

.mapboxgl-popup-cicleText {
  background-color: #fff;
  border-radius: 8px;
  height: 16px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  color: #5c5c5c;
}

.mapboxgl-popup-colorText {
  color: #ffce7d;
  padding-left: 5px;
}

.mapboxgl-popup-building-colorText {
  color: #ffffff;
  padding-left: 5px;
}

.around-iframe {
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>
